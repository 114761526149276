import React, { Component, Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'))
const Page404 = React.lazy(() => import('./views/Pages/Page404'))
const Map = React.lazy(() => import('./views/Dashboard/ShowMap'))
const ProcessFixedReadors = React.lazy(() => import('./views/FixedReadors/ProcessFixedReadors'))

class App extends Component {
  render() {
    return (
      <HashRouter>
        {/* <HashRouter> */}
        <Suspense fallback={loading}>
          <Routes>
            <Route
              exact
              path="/process-fixedReadors"
              name="FixedReador"
              element={<ProcessFixedReadors />}
            />
            <Route exact path="/mapShow" name="Login Page" element={<Map />} />
            <Route exact path="/login" name="Login Page" element={<Login />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route path="*" name="Home" element={<DefaultLayout />} />
          </Routes>
        </Suspense>
      </HashRouter>
    )
  }
}

export default App
